import { AxiosResponse } from 'axios';
import { dev } from './index';

export type SigninProps = {
  email: string;
  password: string;
};
export const signinAPI = async <SigninProps>(body: SigninProps) =>
  await dev.post('/merchant/login', body);

export type SignupProps = {
  owner: string;
  email: string;
  password: string;
  phoneNumber: string;
  name: string;
};

export const signupAPI = async <SignupProps>(
  body: SignupProps & { code: number }
) => await dev.post('/verfiyPhoneAndAddMerchant', body);

export const sendCodeToMerchantPhoneAPI = async <SignupProps>(
  body: SignupProps
) => await dev.post('/sendCodeToMerchantPhone', body);

export type ResetPasswordProps = {
  email: string;
};

export const resetPasswordAPI = async <ResetPasswordProps>(
  body: ResetPasswordProps
) => await dev.post('/merchant/sendResetPasswordEmail', body);

type NewPasswordAPI = (body: {
  password: string;
  idMerchant: string;
  token: string;
}) => Promise<AxiosResponse<any>>;

export const newPasswordAPI: NewPasswordAPI = ({
  idMerchant,
  password,
  token,
}) => dev.post(`merchant/resetPassword/${idMerchant}/${token}`, { password });

export const putStettingsAPI = async ({ body: { merchantId, ...rest } }: any) =>
  await dev.patch(`/settings/general/${merchantId}`, rest);

export const changePasswordAPI = async ({
  body: { merchantId, ...rest },
}: any) => await dev.patch(`/settings/security/${merchantId}`, rest);

export const signoutAPI = async () => await dev.get('/merchant/logout');
export const getInfoAPI = async () => await dev.get('/merchant/info');
