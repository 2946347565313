import { Router } from '@reach/router';
import React from 'react';
import { Route } from '../components/Common';
import { useScript, useScriptWithUrl } from '../hooks/useScript';
import Branding from '../pages/branding';
import { Campaigns } from '../pages/campaigns';
import Home from '../pages/home';
import NotFound from '../pages/notFound';
import Notifications from '../pages/notifications';
import Programs from '../pages/programs';
import { Referral } from '../pages/programs/referral/index';
import { Reviews } from '../pages/reviews';
import segmentation from '../pages/segmentation';
import Settings from '../pages/settings';
import { paths } from '../utils';
import { Layout } from './PagesLayout';
import { RequireIntegration } from './Routes/authorization/require-integration';

export const CompletedApp = () => {
  useBeamAndUsetiful();

  return (
    <Layout>
      <Router>
        <Route path={paths.dashboard} component={Home} />
        <RequireIntegration path={paths.programs} component={Programs} />
        <RequireIntegration path={paths.programs + '/*'} component={Programs} />
        <RequireIntegration path={paths.branding} component={Branding} />
        <RequireIntegration path={paths.settings} component={Settings} />
        <RequireIntegration
          path={paths.notifications}
          component={Notifications}
        />

        <RequireIntegration
          path={paths.campaign + '/*'}
          component={Campaigns}
        />

        <RequireIntegration path={paths.settings + '/*'} component={Settings} />
        <RequireIntegration
          path={paths.segmentation + '/*'}
          component={segmentation}
        />
        <RequireIntegration
          path={paths.referrals + '/*'}
          component={Referral}
        />
        <RequireIntegration path={paths.reviews + '/*'} component={Reviews} />
        <NotFound default />
      </Router>
    </Layout>
  );
};

const useBeamAndUsetiful = () => {
  useScript(
    'body',
    `<!-- Usetiful script start -->

    (function (w, d, s) {
      var a = d.getElementsByTagName('head')[0];
      var r = d.createElement('script');
      r.async = 1;
      r.src = s;
      r.setAttribute('id', 'usetifulScript');
      r.dataset.token = "f6238612570cea11625ab0e20af5f0a3";
      a.appendChild(r);
    })(window, document, "https://www.usetiful.com/dist/usetiful.js");

  <!-- Usetiful script end -->`
  );
  useScript(
    'body',
    `   var beamer_config = {
    product_id: "RHwMZkQq49584" //DO NOT CHANGE: This is your product code on Beamer
  };`
  );
  useScriptWithUrl('https://app.getbeamer.com/js/beamer-embed.js');
};
