export const firebaseConfig = {
  apiKey: 'AIzaSyDR8h2U1cqkvQ9DdqNvnlQvG1LbBf9WUww',
  authDomain: 'bonatdev.firebaseapp.com',
  databaseURL: 'https://bonatdev.firebaseio.com',
  projectId: 'bonatdev',
  storageBucket: 'bonatdev.appspot.com',
  messagingSenderId: '211466790943',
  appId: '1:211466790943:web:58b3da6602ee8e27d4cd2a',
};

export const PROD = 'PROD',
  STG = 'STG',
  DEV = 'DEV';

// export const ENV = PROD;

export const ENV: 'PROD' | 'STG' | 'DEV' = PROD;
// export  const ENV = DEV;

// @ts-ignore
export const isProd: boolean = ENV === PROD;

export const CONFIGRATION = {
  env: {
    [PROD]: {
      serverEndPoint: process.env.REACT_APP_PROD_API,
      serverEndPointShared: process.env.REACT_APP_SHARED_PRODUCTION,
      firebaseEnv: 'prod',
      bonatPay: process.env.REACT_APP_PAYMENT_PRODCUTIO,
    },
    [STG]: {
      serverEndPoint: process.env.REACT_APP_STG_API,
      serverEndPointShared: process.env.REACT_APP_SHARED_STAGING,
      firebaseEnv: 'stg',
      bonatPay: process.env.REACT_APP_PAYMENT_STAGING,
    },
    [DEV]: {
      serverEndPoint: process.env.REACT_APP_DEV_API,
      serverEndPointShared: process.env.REACT_APP_SHARED_DEV,
      firebaseEnv: 'dev',
      bonatPay: process.env.REACT_APP_PAYMENT_STAGING,
    },
  },
};
