import './wdyr';
import React from 'react';
import ReactDOM from 'react-dom';
import { Root } from './components/Root';
import './locales';

const container = document.getElementById('root');

console.info('bonat ecom v2.10.5');

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  container
);
